import React, { FC } from 'react';
import {
  PageLayout,
  ProcessPageSlider,
  SlideInUpHeading,
  CTALink,
} from 'components';
import { graphql } from 'gatsby';
import { FlowerIcon } from 'assets/tsx/flower-icon';
import { SeoType } from '../pages/index';

interface OurProcessPageType {
  data: {
    OurProcessPage: {
      title: string;
      callToAction: {
        title: string;
        link: string;
      };
      quotation: {
        content: string;
      };
      seo: SeoType;
      bannerTitle: [{ title: string }];
      ourProcessSlide: {
        id: string;
        title: string;
        className: string;
        description: string;
        image: {
          childImageSharp: {
            fluid: {
              base64: string;
              aspectRatio: number;
              src: string;
              srcSet: string;
              sizes: string;
            };
          };
        };
      }[];
    };
  };
}
const OurProcess: FC<OurProcessPageType> = ({ data }) => {
  const {
    title,
    seo,
    callToAction: { link, title: callToActionTitle },
    quotation: { content: quotation },
    bannerTitle,
    ourProcessSlide,
  } = data.OurProcessPage;
  return (
    <PageLayout
      title={title}
      quotation={quotation}
      metaTitle={seo?.metaTitle ?? title}
      description={seo?.metaDescription}
      image={seo?.shareImage?.publicURL}
      className="container"
    >
      <div className="process-page">
        <h4 className="page-title"> &gt; {title}</h4>
        <div className="process-page-heading-container">
          <h2 className="section_large_heading">
            <SlideInUpHeading delaySec={1.5}>
              {/* TODO pick randomly */}
              {bannerTitle[0].title}
            </SlideInUpHeading>
          </h2>
          <div className="process-page-icon">
            <FlowerIcon />
          </div>
        </div>
        <div className="process-page-slider-wrapper">
          <ProcessPageSlider slides={ourProcessSlide} />
        </div>

        <CTALink to={link}>{callToActionTitle}</CTALink>
      </div>
    </PageLayout>
  );
};
export default OurProcess;

export const query = graphql`
  query OurProcessPageQuery {
    OurProcessPage: strapiOurProcess {
      title
      bannerTitle {
        title
      }
      callToAction {
        link
        title
      }
      quotation {
        content
      }
      seo {
        metaDescription
        metaTitle
        shareImage {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 400, height: 400)
          }
        }
      }
      ourProcessSlide {
        id
        title
        className
        description
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
